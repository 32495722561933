<template>
  <div>
    <v-row>
      <v-col>
        <div>
          <h1> Sucursales </h1>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-list style="background-color: transparent" flat two-line>
          <v-list-item @click="setSucursal(sucursal)">
            <v-list-item-content>
              <v-list-item-title
                ><div class="title">
                  Listado de sucursales
                </div></v-list-item-title
              >
              <v-list-item-subtitle>
                <div>
                  En el detalle de cada local, podrás agregar colaboradores,
                  configurar los medios de cobro de tus cajas.
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn large @click="goToRegisrarSucursal()">
                <v-icon color="grey lighten-1">mdi-plus</v-icon>
                <span class="text-subtitle">Agregar sucursal</span>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-list>
      <v-list-item-group v-if="sucursales.length > 0">
        <v-list-item @click="handleClick(sucursal)" v-for="sucursal in sucursales" :key="sucursal.id">
          <v-list-item-avatar>
            <v-icon dark>mdi-bank</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ sucursal.nombre }}</v-list-item-title>
            <v-list-item-subtitle>{{
              sucursal.direccion
            }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon>
              <v-icon color="grey lighten-1">mdi-menu-right</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
      <v-list-item v-else>
        <v-list-item-content>No se encontraron sucursales</v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "EmpresaSucursalesList",
  props: {
    empresa: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sucursales: [],
    };
  },
  methods: {
     handleClick(sucursal) {
      this.$router.push({
        name: "sucursalDatosGenerales",
        params: { uuid_sucursal: sucursal.uuid, sucursal: sucursal },
      });
    },
        goToRegisrarSucursal() {
      this.$router.push({
        name: "registrarSucursalEmpresa",
      });
    },
  
  },
watch: {
  // Observa cambios en la propiedad 'empresa'
  empresa: {
    immediate: true, // Para ejecutar la función cuando se crea el componente
    handler(newEmpresa) {
      if (!newEmpresa && this.$route.params.uuid) {
        // Si no hay 'empresa', pero hay un 'uuid' en los parámetros de la ruta
        const uuid = this.$route.params.uuid;

        // Realiza la solicitud de sucursales según el 'uuid'
        this.axios
          .get(`/sucursales?uuid=${uuid}`)
          .then((response) => {
            this.sucursales = response.data;
          });
      } else if (newEmpresa && newEmpresa.uuid) {
        // Si hay 'empresa', utiliza su 'uuid'
        this.axios
          .get(`/sucursales?uuid=${newEmpresa.uuid}`)
          .then((response) => {
            this.sucursales = response.data;
          });
      }
    },
  },
},
};
</script>

<style lang="scss" scoped>
</style>
